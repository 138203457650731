@font-face {
  font-family: LatoB;
  src: url("../../Assets/Fonts/Lato-Bold.ttf");
  font-display: swap;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 850px;
  width: 600px;
  background-color: #ffffff;
  border: none;
  overflow-y: auto;
  /* font-family: Lato; */
  z-index: 900;
  border-radius: 10px;
}

.backCont {
  padding: 10px;
}

.Modal:focus {
  outline: 0;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 900;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 400px;
}

.header {
  flex: 1;
  display: flex;
  padding: 0px 40px;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.button {
  padding: 8px 40px;
  background-color: #416ce1;
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 18px;
}

.left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.23px;
  color: #404a57;
  font-family: LatoB;
}

.table {
  flex: 9;
  padding: 0px 40px;
}

@media (max-width: 576px) {
  .table {
    padding: 0;
  }
  .header {
    flex: 1;
    display: flex;
    padding: 20px 20px;
  }
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.23px;
    color: #404a57;
    font-family: LatoB;
  }
  .button {
    padding: 7px 12px;
    background-color: #416ce1;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 13px;
  }
  .Modal {
    max-height: 90vh;
    width: 100%;
    overflow: scroll;
  }
}
