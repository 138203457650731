.carouselBackground {
  width: 250px;
  height: 670px;
  background: url("../../../Assets/home-images/mockup.svg") no-repeat center;
  background-size: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.car {
  width: 68%;
}

.carouselItem {
  height: 100%;
  width: 100%;
}
