@font-face {
  font-family: LatoB;
  src: url('../../../Assets/Fonts/Lato-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: sFPRo;
  src: url('../../../Assets/Fonts/SourceSansPro-Semibold.otf');
  font-display: swap;
}

.container {
  display: flex;
  flex-direction: column;
}
.imgContainer {
  width: 100%;

  display: flex;
  justify-content: flex-end;
}

.crossButton {
  cursor: pointer;
  height: 45px;
  width: 45px;
}
.connectWith {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.connectText {
  font-family: sspP;
  font-size: 15px;
  color: #72768c;
  position: relative;
  width: 50%;
  text-align: center;
  background-color: white;
  z-index: 1;
}

.connectText::before {
  content: '';
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.08);
  margin: 0 auto;
  width: 100%;
  height: 2px;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.header {
  font-family: sFPRo;
  text-align: center;
  font-size: 25px;
}

.logoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.logo {
  height: 250px;
  width: 200px;
}

.buttonDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emailButton {
  display: flex;
  background-color: #406bdf;
  border: 2px solid #406bdf;
  color: white;
  outline: none;
  border-radius: 2px;
  padding: 10px 20px;
  width: 240px;
  border-radius: 9px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
}

.googleButton {
  display: flex;
  background-color: white;
  color: #4d4f5c;
  border: 2px solid #406bdf;
  outline: none;
  border-radius: 2px;
  padding: 10px;
  width: 240px;
  padding: 10px 20px;
  border-radius: 9px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
}

.fbButton {
  display: flex;
  background-color: #1778f2;
  color: #fff;
  border: 2px solid #1778f2;
  outline: none;
  border-radius: 2px;
  padding: 10px;
  width: 240px;
  padding: 10px 20px;
  border-radius: 9px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
}

.buttonCont {
  padding: 10px 0;
}

@media (max-width: 768px) {
  .crossButton {
    width: 35px;
    height: 35px;
  }
}
