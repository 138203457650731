.main {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 12vh 63vh;
  grid-template-areas: "stratPicker stratPicker" "tableDiv pieDiv";
  width: 100%;
}

.messageBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  height: 100%;
}

.fundsLoader {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stratPicker {
  grid-area: stratPicker;

  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-template-rows: 100%;
  grid-template-areas: "empty strategy";
}

.empty {
  grid-area: empty;
  /* background-color: powderblue; */
  height: 100%;
}

.strategy {
  grid-area: strategy;
  align-self: center;
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  grid-template-rows: 8vh;
  grid-template-areas: "myText actualPicker";
}

.myText {
  font-size: medium;
  color: #2b2d38;
  grid-area: myText;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actualPicker {
  grid-area: actualPicker;
}

.pieDiv {
  grid-area: pieDiv;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 600px;
}

.tableDiv {
  grid-area: tableDiv;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  max-height: 600px;
}

@media (max-width: 1340px) {
  .stratPicker {
    grid-template-columns: 8fr 6fr;
  }
}

@media (max-width: 1025px) {
  .main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100px 300px 600px;
    grid-template-areas: "stratPicker" "pieDiv" "tableDiv";
    width: 100%;
  }
  .stratPicker {
    grid-template-columns: 1fr;
    grid-template-areas: "strategy";
  }

  .strategy {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 576px) {
  .tableDiv {
    grid-area: tableDiv;
    display: flex;
    justify-content: center;
    padding-bottom: 0px;
    max-height: 600px;
  }
}
