.gapper {
  margin-top: 10vh;
}
.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.textContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 7%;
  flex: 1;
}
.algo {
  font-size: 36px;
}
.algop {
  font-size: 18px;
}
.img {
  width: 40vw;
}
.carousel {
  margin-top: 3px;
  margin-bottom: 5px;
}
.img1 {
  width: 450px !important;
}
.img2 {
  width: 394px !important;
}
.img3 {
  width: 500px !important;
}
.heading {
  margin-top: 2px;
  margin-bottom: 5px;
  font-size: 28px;
  margin-left: 10%;
}
.item {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  animation-name: slidein;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.back {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  animation-name: slideout;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
@keyframes slidein {
  0% {
    opacity: 0;
    transform: translateX(-5%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes slideout {
  0% {
    opacity: 0;
    transform: translateX(5%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.carouselItem {
  margin: 0 150px;
  box-shadow: 0.5px 0px 5px 0px #f4f4f4;
  padding: 20px;
}

.carouselInner {
  margin-top: 5vh;
}

.carouselInner p {
  font-size: 18px;
}

.carouselItem h6 {
  color: #416ce1;
  font-size: 18px;
}
.itemImage {
  flex: 1.5;
}

.itemContent {
  flex: 1;
}

.text {
  display: block;
}
.starter {
  margin-top: 4vh;
  margin-bottom: 4vh;
}
.regular {
  margin-bottom: 4vh;
  margin-top: 2vh;
}
.carouselIndicators {
  display: flex;
  flex-direction: row;
  margin-top: 4vh;
  margin-bottom: 5vh;
  justify-content: center;
  align-items: center;
}

.carouselBtn {
  height: 1vh;
  width: 2vw;

  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.line {
  width: 100%;
  height: 0.4vh;
  background-color: #416ce165;
}
.lineColored {
  width: 100%;
  height: 0.4vh;
  background-color: #416ce1;
}
.line:hover {
  background-color: #416ce1;
}
.carouselBtn + .carouselBtn {
  margin-left: 7px;
}
@media (max-width: 575px) {
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    height: 77vh;
  }
  .algo {
    font-size: 24px;
  }
  .algop {
    font-size: 16px;
  }
  .textContent {
    margin-left: 0%;
  }
  .algo {
    font-size: 30px;
    text-align: center;
  }
  .algop {
    font-size: 16px;
    text-align: center;
    margin: 8px 10px;
  }
  .img {
    margin: 0 auto;
    width: 80%;
    display: block;
    margin-bottom: 22px;
    margin-top: 18px;
    float: none;
  }
  .img1 {
    width: 295px !important;
    margin: 0 auto;
  }
  .img2 {
    width: 310px !important;
    margin: 0 auto;
  }
  .img3 {
    width: 330px !important;
    margin: 0 auto;
  }
  .item {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .back {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .carouselBtn {
    height: 1vh;
    width: 4vw;

    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  .carouselInner p {
    font-size: 14px;
  }

  .carouselItem h6 {
    color: #416ce1;
    font-size: 16px;
  }
  .carouselItem {
    margin: 0 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    width: 100%;
  }
  .algo {
    font-size: 30px;
  }
  .algop {
    font-size: 16px;
  }
  .img {
    margin: 0 auto;
    width: 500px;
    display: block;
    margin-bottom: 22px;
    margin-top: 18px;
    float: none;
  }
  .algo {
    font-size: 34px;
    text-align: center;
  }
  .algop {
    font-size: 18px;
    width: 99%;
    text-align: center;
  }
  .textContent {
    margin-left: 1%;
  }
  .item {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .back {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .carouselBtn {
    height: 1vh;
    width: 4vw;

    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  .carouselInner p {
    font-size: 14px;
  }

  .carouselItem h6 {
    color: #416ce1;
    font-size: 16px;
  }
  .carouselItem {
    margin: 0 80px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1122px) {
  .img {
    width: 585px;
  }
  .item {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .back {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .carouselBtn {
    height: 1vh;
    width: 4vw;

    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  .carouselItem {
    margin: 0 110px;
  }
}
