@font-face {
  font-family: Lato;
  src: url("../../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

.columnText {
  font-family: Lato;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.first {
  border-top-left-radius: 10px;
}

.last {
  border-top-right-radius: 10px;
}

.textWrapper {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.text {
  flex: 1;
}
.text1 {
  flex: 1;
}
.tooltip {
  display: flex;
  justify-content: center;
  flex: 1;
}

@media (max-width: 1400px) {
  .columnText {
    font-size: 13px;
  }
}


@media (max-width: 576px) {
  .columnText {
    font-size: 12px;
    font-weight: 400;
  }
}
