.root {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;

  display: grid;
  grid-template-rows: auto auto auto auto auto auto auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'main' 'text1' 'row12' 'editA' 'blueCards' 'works' 'testimonials' 'blog';
  margin-top: 90px;
}

/* ********************************************* */

.main {
  grid-area: main;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'text mySvg';
  overflow-x: hidden;
  min-height: 800px;
  width: 100%;
}

.typingText {
  flex: 1;
  display: flex;
  padding: 0 100px;
  align-items: flex-end;
  font-size: 40px;
}

.lowerText {
  flex: 2;
  
  font-size: 16px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  flex-direction: row;
  padding: 20px 0 0 0px;
}

.appStore {
  margin-left: 20px;
  width: 170px;
  height: 40px;
  background-color: #3d86ef;
  border-radius: 20px;
  color: white;
  border: none;
  outline: none;
}

.link {
  text-decoration: none;
  color: white;
}

.link:hover {
  text-decoration: none;
  color: white;
}

.googlePLay {
  width: 170px;
  height: 40px;
  background-color: black;
  border-radius: 20px;
  color: white;
  border: none;
  outline: none;
}

.text {
  grid-area: text;
  font-size: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.mySvg {
  grid-area: mySvg;
  background-image: url('../../Assets/home-images/bg.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: -20%;
  position: relative;
  align-items: center;
  justify-content: center;
}

.innerSvg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 100px;
}

.gif {
  height: 450px;
}

.img {
  height: 300px;
  z-index: 999;
}

/* ********************************************* */

.works {
  height: 100%;
  grid-area: works;
  display: grid;
  background-color: #f1f1f1;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'worksText worksImage';
}

.worksText {
  grid-area: worksText;
}

.worksImage {
  grid-area: worksImage;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

/* ********************************************* */

.blueCards {
  grid-area: blueCards;
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 450px;
  padding: 25px 15px;
}
.imgWrapper {
  height: 300px;
}
.blueImg {
  height: 100%;
}

.blueImg + .blueImg {
  margin-left: 50px;
}
.textUp {
  padding: 0px;
  font-size: 20px;
}
/* ********************************************* */

.editA {
  grid-area: editA;
  min-height: 600px;
  max-height: 750px;
}

.feesContent {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 25px;
}

.msgFees {
  width: 35%;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  height: 450px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #3a67e4;
}

.feesHeader {
  height: 35%;
  border-bottom: 1px solid #3a67e4;
  display: flex;
}

.feeImage {
  height: 125px;
  width: 125px;
}

.msgContent {
  padding: 30px 0;
}

.feesGraph {
  width: 35%;
  height: 450px;
}
/* ********************************************* */

.testimonials {
  grid-area: testimonials;
  background: linear-gradient(to right, #436cda, #b27ce0);
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.carPro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mytestHeader {
  font-size: 25px;
  color: black;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.mytestHeader1 {
  grid-area: text1;
  font-size: 25px;
  color: black;

  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.feesText {
  width: 80%;
  padding: 0 20px;
  margin: 0;
}

.amount {
  font-size: 40px;
  font-family: sspb;
}

.amountCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  padding-top: 10px;
}

.afterDecimal {
  font-size: 20px;
  padding-top: 8px;
}

.imgfees {
  flex: 1;
  display: flex;
  justify-content: center;
}

.feesSavedText {
  font-size: 20px;
}

.testHeader {
  font-size: 40px;
  color: white;
}

/* ********************************************* */

.blog {
  grid-area: blog;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 800px;
}

@media (min-width: 1100px) {
  .row12 {
    min-height: 150px;
    grid-area: row12;
    /* display: flex; */
    flex-direction: column;
    margin-top: 50px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .mytestHeader1 {
    font-size: 25px;
    color: black;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    margin-left: 30px;
  }
  .row21 {
    min-height: 150px;

    /* display: flex; */
    flex-direction: column;
    margin-top: -450px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .spe {
    margin-left: 50px;
  }
  .empImg {
    position: relative;
    flex: 1;
    height: 40%;
    width: 40%;
    min-width: 170px;
    min-height: 170px;
    max-width: 170px;
    max-height: 170px;
    margin-left: 40px;
    margin-top: 20px;
    cursor: pointer;
  }
  .main1 {
    display: grid;

    overflow-x: hidden;
    min-height: 800px;
    width: 100%;
  }

  .text1 {
    font-size: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  .searchParent {
    width: 45%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    align-self: center;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #31333e;
    margin-left: 63px;
    margin-bottom: 250px;
    margin-top: 60px;
  }
  .searchParent1 {
    width: 45%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #31333e;
    margin-left: 43px;

    margin-top: 60px;
  }
}

/* ********************************************* */
@media (max-width: 1600px) {
  .blueCards {
    min-height: 400px;
    width: 100%;
  }
  .mytestHeader1 {
    grid-area: text1;
    font-size: 25px;
    color: black;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    margin-left: 30px;
  }
  .row12 {
    min-height: 150px;
    grid-area: row12;
    /* display: flex; */
    flex-direction: column;
    margin-top: 50px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .row21 {
    min-height: 150px;

    /* display: flex; */
    flex-direction: column;
    margin-top: -450px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .spe {
    margin-left: 50px;
  }
  .empImg {
    position: relative;
    flex: 1;
    height: 40%;
    width: 40%;
    min-width: 170px;
    min-height: 170px;
    max-width: 170px;
    max-height: 170px;
    margin-left: 40px;
    margin-top: 20px;
    cursor: pointer;
  }
  .main1 {
    display: grid;

    overflow-x: hidden;
    min-height: 800px;
    width: 100%;
  }

  .text1 {
    font-size: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  .imgWrapper {
    height: 250px;
  }
}
@media (max-width: 1440px) {
  .buttons {
    display: flex;
    flex-direction: row;
    padding: 20px 0 0 0;
  }
  .mytestHeader1 {
    grid-area: text1;
    font-size: 25px;
    color: black;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    margin-left: 30px;
  }
  .typingText {
    flex: 1;
    display: flex;
    align-items: flex-end;
    font-size: 40px;
  }
  .editA {
    max-height: 1500px;
  }

  .row12 {
    min-height: 150px;
    grid-area: row12;
    /* display: flex; */
    flex-direction: column;
    margin-top: 50px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .row21 {
    min-height: 150px;

    /* display: flex; */
    flex-direction: column;
    margin-top: -450px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .spe {
    margin-left: 50px;
  }
  .empImg {
    position: relative;
    flex: 1;
    height: 40%;
    width: 40%;
    min-width: 170px;
    min-height: 170px;
    max-width: 170px;
    max-height: 170px;
    margin-left: 40px;
    margin-top: 20px;
    cursor: pointer;
  }
  .main1 {
    display: grid;

    overflow-x: hidden;
    min-height: 800px;
    width: 100%;
  }

  .text1 {
    font-size: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }
}

@media (max-width: 1280px) {
  .blueCards {
    min-height: 350px;
    width: 100%;
  }
  .mytestHeader1 {
    grid-area: text1;
    font-size: 25px;
    color: black;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    margin-left: 30px;
  }
  .imgWrapper {
    height: 200px;
  }

  .blueImg + .blueImg {
    margin-left: 20px;
  }

  .mytestHeader {
    margin: 25px 0;
  }

  .msgFees {
    width: 40%;
  }

  .feesGraph {
    width: 40%;
  }
}

@media (max-width: 1024px) {
  .root {
    grid-template-rows: auto auto 800px auto auto auto auto;
    grid-template-columns: 1fr;
    grid-template-areas: 'main' 'text1' 'row12' 'works' 'editA' 'blueCards' 'testimonials' 'blog';
  }

  .main {
    grid-template-columns: 1fr;
    grid-template-rows: 6fr 4fr;
    grid-template-areas: 'mySvg' 'text';
  }

  .mySvg {
    grid-area: mySvg;
    background-image: none;
    position: relative;
  }

  .innerSvg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    padding-left: 0px;
  }
  .row12 {
    min-height: 150px;
    grid-area: row12;
    /* display: flex; */
    flex-direction: column;
    margin-top: 50px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .mytestHeader1 {
    grid-area: text1;
    font-size: 25px;
    color: black;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    margin-left: 30px;
  }
  .row21 {
    min-height: 150px;

    /* display: flex; */
    flex-direction: column;
    margin-top: -450px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .spe {
    margin-left: 50px;
  }
  .empImg {
    position: relative;
    flex: 1;
    height: 40%;
    width: 40%;
    min-width: 170px;
    min-height: 170px;
    max-width: 170px;
    max-height: 170px;
    margin-left: 40px;
    margin-top: 20px;
    cursor: pointer;
  }
  .main1 {
    display: grid;

    overflow-x: hidden;
    min-height: 800px;
    width: 100%;
  }

  .text1 {
    font-size: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  .works {
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 3fr 5fr;
    grid-template-areas: 'worksText' 'worksImage';
  }
  .blueCards {
    min-height: 750px;
    width: 100%;
    flex-direction: column;
  }
  .imgWrapper {
    height: 200px;
  }

  .blueImg + .blueImg {
    margin-top: 25px;
  }

  .mytestHeader {
    margin: 30px 0;
  }

  .msgFees {
    width: 42%;
    height: 400px;
  }

  .feesGraph {
    width: 42%;
    height: 400px;
  }
}

@media (max-width: 868px) {
  .root {
    grid-template-rows: auto auto 850px auto auto auto auto;
    grid-template-columns: 1fr;
    grid-template-areas: 'main' 'text1' 'row12' 'works' 'editA' 'blueCards' 'testimonials' 'blog';
  }

  .row12 {
    min-height: 150px;
    grid-area: row12;
    /* display: flex; */
    flex-direction: column;
    margin-top: 50px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;
    align-items: center;
  }
  .row21 {
    min-height: 150px;

    /* display: flex; */
    flex-direction: column;
    margin-top: -450px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .spe {
    margin-left: 50px;
  }
  .empImg {
    position: relative;
    flex: 1;
    height: 30%;
    width: 30%;
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    margin-left: 40px;
    margin-top: 20px;
  }
  .main1 {
    display: grid;

    overflow-x: hidden;
    min-height: 800px;
    width: 100%;
  }

  .text1 {
    font-size: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  .main {
    grid-template-columns: 1fr;
    grid-template-rows: 3fr 2fr;
    grid-template-areas: 'mySvg' 'text';
  }
  .editA {
    padding: 0px 50px;
    max-height: 1600px;
  }
  .feesContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
  }
  .mytestHeader {
    margin: 20px 0 0px 0;
  }
  .searchParent {
    width: 90%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-self: center;
    margin-left: 25px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #31333e;
    margin-bottom: 5px;
  }

  .msgFees {
    width: 80%;
    height: 400px;
    margin: 0 0 20px 0;
  }

  .feesGraph {
    width: 80%;
    height: 400px;
  }
  .blog {
    min-height: 700px;
  }
}

@media (max-width: 700px) {
  .editA {
    padding: 0px 40px;
    max-height: 1600px;
  }
  .mytestHeader1 {
    grid-area: text1;
    font-size: 20px;
    color: black;
    text-align: left;
    margin-top: 17x;
    margin-bottom: 16px;
    display: flex;
    margin-left: 20px;
  }
  .row12 {
    min-height: 150px;
    grid-area: row12;
    /* display: flex; */
    flex-direction: column;
    margin-top: 20px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;
  }
  .row21 {
    min-height: 150px;
    justify-content: center;
    /* display: flex; */
    flex-direction: column;
    margin-top: -450px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .spe {
    margin-left: 50px;
  }
  .empImg {
    position: relative;
    flex: 1;
    height: 40%;
    width: 40%;
    min-width: 170px;
    min-height: 170px;
    max-width: 170px;
    max-height: 170px;
    margin-left: 40px;
    margin-top: 20px;
  }
  .main1 {
    display: grid;

    overflow-x: hidden;
    min-height: 800px;
    width: 100%;
  }

  .text1 {
    font-size: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  .searchParent {
    width: 90%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-self: center;
    margin-left: 25px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #31333e;
    margin-bottom: 5px;
  }
}
@media (min-width: 380px) and (max-width: 600px) {
  .row12 {
    min-height: 150px;
    grid-area: row12;
    /* display: flex; */
    flex-direction: column;
    margin-top: 40px;

    flex-wrap: wrap;
    align-content: space-between;
    margin-left: 10%;
  }
  .mytestHeader1 {
    grid-area: text1;
    font-size: 19px;
    color: black;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    margin-left: 5px;
  }
  .spe {
    margin-left: 20px;
    font-size: 17px;
  }
  .empImg {
    position: relative;
    flex: 1;
    height: 30%;
    width: 30%;
    min-width: 90px;
    min-height: 90px;
    max-width: 90px;
    max-height: 90px;
    margin-left: 10px;
    margin-top: 20px;
  }
}
@media (max-width: 576px) {
  .root {
    grid-template-rows: auto auto auto auto auto auto auto;
    grid-template-columns: 1fr;
    grid-template-areas: 'main' 'text1' 'row12' 'editA' 'blueCards' 'works' 'testimonials' 'blog';
  }

  .row12 {
    min-height: 150px;
    grid-area: row12;
    /* display: flex; */
    flex-direction: column;
    margin-top: 10px;
    margin-left: 50px;
    flex-wrap: wrap;
    align-content: space-between;
    margin-left: 9%;
  }
  .row21 {
    min-height: 150px;
    justify-content: center;
    /* display: flex; */
    flex-direction: column;
    margin-top: -450px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
    margin-bottom: 20px;
  }
  .mytestHeader1 {
    grid-area: text1;
    font-size: 19px;
    color: black;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    margin-left: 5px;
  }
  .spe {
    margin-left: 20px;
    font-size: 17px;
  }
  .empImg {
    position: relative;
    flex: 1;
    height: 30%;
    width: 30%;
    min-width: 90px;
    min-height: 90px;
    max-width: 90px;
    max-height: 90px;
    margin-left: 10px;
    margin-top: 20px;
    cursor: pointer;
  }
  .main1 {
    display: grid;

    overflow-x: hidden;
    min-height: 800px;
    width: 100%;
  }

  .text1 {
    font-size: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  .searchParent {
    width: 90%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-self: center;
    margin-left: 25px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #31333e;
    margin-bottom: 5px;
  }

  .typingText {
    padding: 0 0;
    display: flex;
    justify-content: center;
    font-size: 27px;
  }
  .lowerText {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 20px 20px 0px 20px;
    
  }

  .googlePLay {
    width: 130px;
    height: 40px;
    background-color: black;
    border-radius: 20px;
    color: white;
    border: none;
    outline: none;
    font-size: 14px;
  }
  .appStore {
    margin-left: 20px;
    width: 130px;
    height: 40px;
    background-color: #3d86ef;
    border-radius: 20px;
    color: white;
    border: none;
    outline: none;
    font-size: 14px;
  }
  .blueCards {
    grid-area: blueCards;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .testHeader {
    font-size: 30px;
  }

  .testimonials {
    justify-content: space-evenly;
  }

  .imgWrapper {
    text-align: center;
    padding: 10px 0px;
    width: 100%;
  }
  .mytestHeader {
    font-size: 20px;
    margin-top: 5px;
  }
  .main {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'mySvg' 'text';
    min-height: 700px;
  }
  .gif {
    height: 200px;
  }
  .editA {
    padding: 0;
  }
  .mytestHeader {
    font-size: 20px;
    text-align: center;
  }

  .msgFees {
    width: 90%;
    height: 380px;
  }
  .feesGraph {
    width: 90%;
    height: 380px;
  }

  .msgContent {
    font-size: 14px;
  }

  .amount {
    font-size: 24px;
    font-family: sspb;
  }

  .afterDecimal {
    font-size: 16px;
  }
  .feesSavedText {
    font-size: 16px;
  }
  .feeImage {
    height: 100px;
    width: 100px;
  }
  .textUp {
    font-size: 14px;
  }
}

.socialsecurityDiv {
  /* position: absolute;
  top: 90vh;*/
  margin-top: 50px;
  margin-right: -160px;
  width: 40vw;
  border: 1px solid #416ce1;
  padding: 10px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  align-content: center;
  box-shadow: 1px 1px 5px 0px grey;
}

.socialsecurityDiv p {
  font-size: 13px;
  font-weight: 800;
}

.socialsecurityButton {
  align-self: center;
  background-color: #416ce1;
  padding: 10px;
  color: #fff;
  border: none;
  outline: none;
  min-width: 180px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0px grey;
  font-size: 14px;
  font-weight: 600;
}

.socialsecurityButton:hover {
  transform: scale(1.02);
}

/* @font-face {
  font-family: ssp;
  src: url('../../Assets//Fonts/SourceSansPro-Regular.otf');
  font-display: swap;
} */

.inputField {
  width: 100%;
  border-radius: 20px;
  font-family: ssp;
  outline: none;
  box-sizing: border-box;
  height: 40px;
  border: solid 1px #416ce1;
  background-color: #ffffff;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
  transition: box-shadow 0.3s;
  font-size: 15px;
  border: none;
  background-color: transparent;
  outline: none;
}

.inputCont {
  flex: 1;
  padding-left: 5px;
}

.hideDiv {
  display: none;
}

.searchWrapper {
  overflow: visible;
  width: 95%;
}

.dropDownDiv {
  position: relative;
  width: 100%;
  z-index: 1000;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noMatchFound {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #404a57;
}

.list {
  background-color: white;
  position: absolute;
  width: 100%;
  padding: 10px 0 10px 0;
  list-style: none;
  margin: 0;
  border: solid 1px #416ce1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(32, 33, 36, 0.28);
}

.listItem {
  text-decoration: none;
  font-size: 12px;
  border-bottom: 1px solid #ededed;
  color: #404a57;
  cursor: pointer;
}

.listItem:hover {
  background-color: #e9e9e9;
}

.innerDiv {
  padding: 10px 0px 10px 50px;
}

.item {
  border-top: 1px solid #f5f5f5;
}

.inputWithIcon {
  display: flex;
  height: 40px;
  max-height: 50px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.096);
  border: solid 1px #416ce1;
  background-color: #ffffff;
  border-radius: 25px;
  outline: none;
  align-items: center;
  padding: 0px 10px;
  margin: 8px 0 0 0;
  box-sizing: border-box;
}

.inputWithIcon .inputIcon {
  color: #416ce1;
  transition: 0.3s;
}

.inputWithIcon .inputField:focus + .inputIcon {
  color: #416ce1;
}

.inputWithIcon.inputIconBg .inputIcon {
  background-color: #aaa;
  color: #fff;
  padding: 9px 4px;
  border-radius: 4px 0 0 4px;
}

@media (max-width: 576px) {
  .inputField {
    width: 100%;
    border-radius: 20px;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    height: 40px;
    font-size: 14px;
  }
}
