@font-face {
  font-family: LatoBold;
  src: url("../../Assets/Fonts/Lato-Bold.ttf");
}
/* For the Check */
.exclaim {
  width: 30px;
  height: 30px;
  margin: 10px;
}

.row2 {
  width: 100%;
  display: grid;
  grid-template-rows: 30px 630px 340px;
  grid-template-columns: 5fr 5fr 4fr;
  grid-template-areas: "title title title" "graphCont graphCont future" "editA editA future";

  grid-gap: 20px;
  padding: 20px;
}

.upper {
  display: flex;
}

.title {
  grid-area: title;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 23px;
  padding: 10px 0px 0px 20px;
  font-family: LatoBold;
}

.graphCont {
  display: flex;
  grid-area: graphCont;
}

.graph1 {
  flex: 1;
}

.graph2 {
  flex: 1;
}

.editA {
  grid-area: editA;
}

.editCard {
  height: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.future {
  grid-area: future;

  background-color: white;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.futureParent {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.smallFuture {
  height: 100%;
  width: 80%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  display: grid;
  place-items: center;
}

.smallFutureHeading {
  height: 10%;
}

.futureGraph {
  grid-area: futureGraph;
}

.futureTable {
  grid-area: futureTable;
}

.bottomText {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1500px) {
  .row2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 60px 650px 320px 570px;
    grid-template-areas: "title title" "graphCont graphCont" "editA editA" "future future";
  }
}

@media (max-width: 1280px) {
  .row2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 900px 700px;
    grid-template-areas: "smallscreen" "futureParent";
    height: auto;
  }

  .smallscreen {
    grid-area: smallscreen;
  }

  .futureParent {
    grid-area: futureParent;
  }

  .smallFuture {
    width: 95%;
    height: 600px;
  }
}

@media (max-width: 1100px) {
  .row2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 900px 700px;
    grid-template-areas: "smallscreen" "futureParent";
    height: 1650px;
  }
}

@media (max-width: 700px) {
  .root {
    max-width: 100%;
    overflow-x: hidden;
  }

  .row2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "smallscreen" "futureParent";
    height: 1350px;
    padding: 0;
  }
  .smallFuture {
    width: 95%;
    height: 400px;
  }
}

@media (max-width: 576px) {
  .picker {
    display: flex;
  }
}
