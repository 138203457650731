.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  min-height: 600px;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  text-align: center;
  margin-bottom: 20px;
}

.blogContainer {
  height: 100%;
  width: 90%;
  margin: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  justify-content: space-evenly;
  box-shadow: 1px 3px 8px rgba(216, 216, 216, 0.123);
}

.links {
  color: #3a67e4;
}

.blogImage {
  width: 100%;
  height: 40%;
  flex: 3;
  object-fit: cover;
  max-height: 200px;
}
.img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 100%;
}

.dateDiv {
  text-align: center;
  padding: 20px 10px 0px 10px;
  font-size: 14px;
  color: #6c757d;
  flex: 1;
}

.blogTitle {
  text-align: center;
  cursor: pointer;
  color: #3a67e4 !important;
  font-size: 20px;
  font-weight: 500;
  padding: 15px 15px 5px 15px;
  flex: 3;
}

.btnDiv {
  align-self: center;
  padding: 3px 12px;
  border-radius: 5px;
  background-color: #3a67e4;
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
}
.btnDiv:hover {
  text-decoration: none;
  color: #3a67e4;
  border: 1px solid #3a67e4;
  background-color: #fff;
}
.carBtns {
  margin-top: 20px;
  text-align: center;
}
.btns {
  background: transparent;
  border: 0;
  font-size: 25px;
  font-weight: 600;
}
.blogContent {
  padding: 0px 25px;
  flex: 3;
}

@media (max-width: 1024px) {
  .blogTitle {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 15px 5px 15px;
    flex: 3;
  }

  .btnDiv {
    align-self: center;
    padding: 4px 12px;
    border-radius: 5px;
    background-color: #3a67e4;
    color: #fff;
    text-decoration: none;
    margin-bottom: 5px;
  }
}

@media (max-width: 576px) {
  .container {
    width: 100%;
    min-height: 650px;
  }
  .header {
    font-size: 35px;
    padding: 20px 0px 10px 0px;
  }
  .dateDiv {
    text-align: center;
    padding: 10px 10px 0px 10px;
    font-size: 14px;
    color: #6c757d;
    flex: 1;
  }
  .blogTitle {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 15px 5px 15px;
  }
  .carDiv {
    /* background-color: chartreuse; */
    width: 100%;
  }
}
