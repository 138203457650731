.mainDiv {
  width: 70vw;
  height: 250px;
  background: url("../../Assets/testimonial-bg.svg");
  background-repeat: no-repeat;
}

.carro {
  width: 70vw;
}

.innerCaro {
  height: 250px;
  width: 100%;
  font-size: 20px;
  background: url("../../Assets/testimonial-bg.svg");
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 50px;
}

@media (max-width: 768px) {
  .mainDiv {
    width: 90vw;
    height: 280px;
  }
  .innerCaro {
    width: 90vw;
    height: 280px;
    font-size: 15px;
  }
  .carro {
    height: 280px;
    width: 90vw;
  }
}

.name-head {
  font-size: 30px;
  font-weight: 400;
}
