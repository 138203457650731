.container {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 65px;
}

.step {
  display: flex;
  text-align: center;
  align-items: center;
  /* margin-top: 20px; */
  position: relative;
  /* background-color: coral; */
}
.iconDiv {
  position: absolute;
  top: 0;
}

.stepper {
  position: absolute;
  top: 0;
  padding-left: 30px;
}
.stepperb {
  padding-left: 0px;
  font-size: 21px;
}

@media (max-width: 576px) {
  .container {
    padding: 20px;
  }
  .Htw {
    font-size: 30px;
    text-align: center;
  }
}
