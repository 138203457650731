@font-face {
  font-family: Lato;
  src: url("../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: LatoB;
  src: url("../../Assets/Fonts/Lato-Bold.ttf");
  font-display: swap;
}
.container {
  width: 100%;
  height: 90vh;
}

.searchDiv {
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  width: 95%;
  padding-left: 10px;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.23px;
  color: #404a57;
  font-family: LatoB;
}

.searchWrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

mainContent {
  height: 75vh;
}
